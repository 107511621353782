import { useLazyQuery, useMutation } from '@apollo/client'
import { Flatfile, PartialRejection, RecordError } from '@flatfile/sdk'
import { GET_TRANSACTIONS_PORTAL_TOKEN } from 'graphql/importers'
import { IMPORT_TRANSACTIONS_FROM_UPLOAD } from 'graphql/transactions.js'
import { useTranslation } from 'react-i18next'
import { toNumber } from 'lodash'
import { useCallback } from 'react'
import { convertStringDateFrToIso } from 'utils/dates.js'
import flatfileConfig from 'config/flatfile.js'

const importTransactionErrors = {
  ALREADY_IN_DB: 'ALREADY_IN_DB',
  INVALID_DATA: 'INVALID_DATA'
}

export default function useTransactionsImporter ({ onImportComplete, accountId }) {
  const { t } = useTranslation()
  const [getTransactionsPortalToken] = useLazyQuery(GET_TRANSACTIONS_PORTAL_TOKEN)
  const [importTransactionsFromUpload] = useMutation(IMPORT_TRANSACTIONS_FROM_UPLOAD)

  const getToken = useCallback(async () => {
    const { data: { getTransactionsPortalToken: { token } } } = await getTransactionsPortalToken()
    return token
  }, [getTransactionsPortalToken])

  const importChunk = useCallback(async (chunk, next) => {
    const records = chunk.records

    const transactionsParams = records.map(record => ({
      recordId: record.recordId,
      amount: toNumber(record.data.amount),
      externalId: record.data.externalId,
      type: record.data.type,
      date: convertStringDateFrToIso(record.data.date),
      description: record.data.description
    }))

    const rejections = []
    try {
      const result = await importTransactionsFromUpload({
        variables: {
          accountId,
          transactionsParams
        }
      })

      const { importTransactionsResult } = result.data.importTransactionsFromUpload

      for (const transactionImportResult of importTransactionsResult) {
        if (transactionImportResult.imported) {
          continue
        }

        let message = ''
        switch (transactionImportResult.error) {
          case importTransactionErrors.ALREADY_IN_DB:
            message = t('hook.useTransactionsImport.already_in_db')
            break
          case importTransactionErrors.INVALID_DATA:
            message = t('hook.useTransactionsImport.invalid_data', { errorMessage: transactionImportResult.errorMessage })
            break
        }
        const field = ['type', 'amount', 'description'].includes(transactionImportResult.errorField) ? transactionImportResult.errorField : 'externalId'
        rejections.push(
          new RecordError(transactionImportResult.recordId, [
            {
              field,
              message
            }
          ])
        )
      }
    } catch (error) {
      for (const recordId of transactionsParams.map(transactionsParam => transactionsParam.recordId)) {
        rejections.push(
          new RecordError(recordId, [
            {
              field: 'externalId',
              message: t('hook.useTransactionsImport.error')
            }
          ])
        )
      }
    }
    next(new PartialRejection(rejections))
  }, [importTransactionsFromUpload, accountId, t])

  const triggerImport = useCallback(async (data) => {
    await Flatfile.requestDataFromUser({
      ...flatfileConfig,
      chunkSize: 50,
      token: getToken,
      onData: importChunk,
      onComplete: onImportComplete,
      onError: console.warn
    })
  }, [getToken, importChunk, onImportComplete])

  return { triggerImport }
}
